export const valentinesDayMenu = {
  welcomeDrink: "Love Potion",
  firstCourse: [
    {
      name: "Masala Papad",
      description: "Crispy papad seasoned with a blend of spices.",
      image: "/assets/images/food/masala-papad.jpeg",
    },
    {
      name: "Love Potion",
      description: "A refreshing welcome drink to start your meal.",
      image: "/assets/images/food/love-potion.jpeg",
    },
  ],
  entrees: [
    {
      name: "Chicken Lollipop",
      description: "Succulent chicken wings seasoned and fried to a delightful crisp.",
      image: "/assets/images/food/chicken-lollipop.jpeg",
    },
    {
      name: "Samosa",
      description: "Triangular savory pastry filled with spicy potatoes and peas.",
      image: "/assets/images/food/samosa-on-wheels.jpeg",
    },
    {
      name: "Pav Burger",
      description: "A spicy and flavorful vegetarian burger.",
      image: "/assets/images/food/pao-burger.jpeg",
    },
    {
      name: "Vada Bao",
      description: "A soft bao bun filled with a deliciously seasoned vada.",
      image: "/assets/images/food/vada-bao.jpeg",
    },
  ],
  mains: [
    {
      name: "Green Chicken w/ Naan or Rice",
      description: "Tender chicken cooked in a flavorful green herb sauce served with naan of your choice.",
      image: "/assets/images/food/green-chicken.jpeg",
    },
    {
      name: "Aranchini Malai w/ Naan or Rice",
      description:
        "Cottage cheese filled crispy balls served with a tangy & creamy sauce, served with naan of your choice",
      image: "/assets/images/food/aranchini-malai.jpeg",
    },
    {
      name: "Butter Chicken Lasagna",
      description: "A unique fusion of Indian butter chicken and Italian lasagna.",
      image: "/assets/images/food/butter-chicken-lasagna.jpeg",
    },
    {
      name: "Butter Paneer Lasagna",
      description: "Paneer and pasta layered with rich butter tomato sauce.",
      image: "/assets/images/food/butter-chicken-lasagna.jpeg",
    },
    {
      name: "Dal Makhni w/ Naan or Rice",
      description: "A rich and creamy lentil curry served with naan of your choice.",
      image: "/assets/images/food/dal-makhani.jpeg",
    },
  ],
  desserts: [
    {
      name: "Ambrosial Mango Royale",
      description: "A mango dessert that will leave you wanting more.",
      image: "/assets/images/food/ambrosial-mango-royale.jpeg",
    },
    {
      name: "Gulabjamun w/ ice cream",
      description: "A traditional Indian dessert served with a scoop of vanilla ice cream.",
      image: "/assets/images/food/gulab-jamun.png",
    },
  ],
};
