import React from "react";
import HeroSection from "../components/HeroSection";
import SpecialDishSection from "../components/SpecialDishSection";
import TestimonialsSection from "../components/TestimonialsSection";
import ServiceSection from "../components/ServiceSection";
import { Helmet } from "react-helmet";

export const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Ambrosia - Modern Indian Restaurant in South Melbourne | Fusion Cuisine</title>
        <meta
          name="description"
          content="Experience the finest modern Indian cuisine at Ambrosia - The Culinary Exchange in South Melbourne. Enjoy fusion dishes, fine dining, and vegetarian options. Book your table for an unforgettable culinary journey today!"
        />
        <meta
          name="keywords"
          content="Indian restaurant, fusion cuisine, South Melbourne dining, vegetarian options, fine dining, modern Indian food"
        />
        <link rel="canonical" href="https://theambrosiarestaurant.com.au/" />
      </Helmet>
      <div style={{ marginTop: "-150px" }}>
        <HeroSection />
        <ServiceSection />
        <SpecialDishSection />
        <TestimonialsSection />
      </div>
    </>
  );
};

export default LandingPage;
