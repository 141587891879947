import MenuSection from "../components/MenuSection";
import menuData from "../utils/menuData";
import ServiceSection from "../components/ServiceSection";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const MenuPage = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      // Using setTimeout to add a delay to ensure the page is fully rendered
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth" });
          }, 2100);
        }
      }, 0);
    }
  }, []); // Empty dependency array means this effect will run once after the initial render

  return (
    <>
      <Helmet>
        <title>Explore Ambrosia's Menu - South Melbourne's Culinary Delights</title>
        <meta
          name="description"
          content="Savor the diverse menu at Ambrosia, South Melbourne. From timeless classics to modern culinary creations, our menu is a testament to gourmet excellence and innovative cuisine."
        />
        {/* Additional relevant meta tags */}
      </Helmet>
      <ServiceSection />
      <section id="food">
        <MenuSection title="Entrées" subtitle="Our Exquisite Starters" items={menuData.entrees} />
        <MenuSection title="Mains" subtitle="Savor the Flavor" items={menuData.mains} />
        <MenuSection title="Sides" subtitle="Perfect Pairings" items={menuData.sides} />
        <MenuSection title="Desserts" subtitle="A Sweet Finale" items={menuData.desserts} />
        <div className="center-container">
          <Link className="btn btn-primary" to="/menu/food.pdf" target="_blank">
            <span className="text text-1">View Food Menu (PDF)</span>
            <span aria-hidden="true" className="text text-2">
              View Food Menu (PDF)
            </span>
          </Link>
        </div>
      </section>
      <section id="drinks">
        <MenuSection title="Cocktails" subtitle="Crafted with Care" items={menuData.cocktails} />
        <MenuSection title="Red Wines" subtitle="Rich and Refined" items={menuData.wines.red} />
        <MenuSection title="White Wines" subtitle="Crisp and Refreshing" items={menuData.wines.white} />
        <MenuSection title="Sparkling Wines" subtitle="Bubbly and Festive" items={menuData.wines.sparkling} />
        <MenuSection title="Rose Wines" subtitle="Blush and Elegance" items={menuData.wines.rose} />
        <MenuSection title="Beers" subtitle="Brews to Choose" items={menuData.beers} />
        <MenuSection title="Spirits" subtitle="Distilled Delights" items={menuData.spirits} />
        <MenuSection title="Liquer" subtitle="Luxurious Layers" items={menuData.liqueur} />
        <MenuSection title="Mocktails" subtitle="Non-Alcoholic Wonders" items={menuData.mocktails} />
        <div className="center-container" style={{ marginBottom: "20px", marginTop: "-20px" }}>
          <Link className="btn btn-primary" to="/menu/drinks.pdf" target="_blank">
            <span className="text text-1">View Drinks Menu (PDF)</span>
            <span aria-hidden="true" className="text text-2">
              View Drinks Menu (PDF)
            </span>
          </Link>
        </div>
      </section>
    </>
  );
};

export default MenuPage;
