import React, { useState, useEffect } from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import SectionHeading from "../SectionHeading";
import "./TestimonialsSection.css";
import placesData from "../../reviews.json";

const TestimonialsSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const reviews = placesData && placesData.result ? placesData.result.reviews : [];

  const nextSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide === reviews.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide === 0 ? reviews.length - 1 : prevSlide - 1));
  };

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 6000); // Change slide every 3 seconds
    return () => clearInterval(slideInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!placesData || !placesData.result || !placesData.result.reviews) return <></>;

  return (
    <section
      aria-label="testimonials"
      className="section testi text-center has-bg-image"
      style={{ backgroundImage: "url('./assets/images/testimonial-bg.jpg')" }}
    >
      <SectionHeading title="Hear it from Our Diners" />
      <div className="container">
        <div className="quote">”</div>
        <ul className="testi-slider">
          {reviews.map((review, index) => (
            <li key={index} className={`testi-item ${activeSlide === index ? "active" : ""}`}>
              <p className="testi-text">{review.text}</p>
              <div className="wrapper">
                <div className="separator"></div>
                <div className="separator"></div>
                <div className="separator"></div>
              </div>
              <div className="profile">
                <img
                  alt={review.author_name}
                  className="img"
                  height="100"
                  loading="lazy"
                  src={review.profile_photo_url}
                  width="100"
                />
                <p className="label-2 profile-name">{review.author_name}</p>
              </div>
            </li>
          ))}
        </ul>
        <button aria-label="slide to previous" className="slider-btn prev" onClick={prevSlide}>
          <IoChevronBack />
        </button>
        <button aria-label="slide to next" className="slider-btn next" onClick={nextSlide}>
          <IoChevronForward />
        </button>
      </div>
    </section>
  );
};

export default TestimonialsSection;
