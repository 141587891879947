import React from "react";
import { Link } from "react-router-dom";
import "./SpecialDishSection.css"; // Importing the corresponding CSS

const SpecialDishSection = () => (
  <section aria-labelledby="dish-label" className="special-dish text-center">
    <div className="special-dish-banner">
      <img
        alt="Ambrosia's Famous Butter Chicken Lasagna - South Melbourne"
        className="img-cover"
        height="900"
        loading="lazy"
        src="/assets/images/special-dish-banner.jpg"
        width="940"
      />
    </div>
    <div className="special-dish-content bg-black-10">
      <div className="container">
        <img alt="badge" className="abs-img" height="41" loading="lazy" src="/assets/images/badge-1.png" width="28" />
        <h3 className="section-subtitle label-2">SPECIAL DISH</h3>
        <h2 className="headline-1 section-title">BUTTER CHICKEN LASAGNA</h2>
        <p className="section-text">
          Indulge in our signature Butter Chicken Lasagna, a delightful fusion dish that combines the comfort of Italian
          cuisine with the robust flavours of Indian cooking. This dish features layers of creamy lasagna interspersed
          with rich and tangy butter chicken, cooked to perfection in a tandoor. The succulent chicken, coupled with the
          velvety texture of béchamel sauce and the sharpness of mature cheddar, makes for an unforgettable culinary
          experience. It's a unique blend of flavours that showcases the innovative spirit of our kitchen. Enjoy this
          heartwarming dish that unites the best of two culinary worlds.
        </p>
        <Link className="btn btn-primary" to="/menu">
          <span className="text text-1">View All Menu</span>
          <span aria-hidden="true" className="text text-2">
            View All Menu
          </span>
        </Link>
      </div>
    </div>
    <img alt="" className="shape shape-1" height="359" loading="lazy" src="/assets/images/shape-4.png" width="179" />
    <img alt="" className="shape shape-2" height="462" loading="lazy" src="/assets/images/shape-9.png" width="351" />
  </section>
);

export default SpecialDishSection;
