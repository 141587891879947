import React from "react";
import { Helmet } from "react-helmet";
import "./LocationSection.css"; // Importing the corresponding CSS

const LocationSection = () => (
  <section aria-label="location" className="section features text-center" id="location">
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Restaurant",
            "name": "Ambrosia - The Culinary Exchange",
            "image": "https://theambrosiarestaurant.com.au/assets/images/ambrosia-front.jpg",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "274 Park Street",
              "addressLocality": "South Melbourne",
              "addressRegion": "VIC",
              "postalCode": "3205",
              "addressCountry": "AU"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": -37.8325,
              "longitude": 144.9625
            },
            "url": "https://theambrosiarestaurant.com.au",
            "telephone": "+61480246220",
            "servesCuisine": ["Indian", "Modern Indian", "Fusion"],
            "priceRange": "$$",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
                "opens": "18:00",
                "closes": "21:00"
              }
            ]
          }
        `}
      </script>
    </Helmet>
    <div className="container">
      <p className="section-subtitle label-2">Visit Us in South Melbourne</p>
      <h2 className="headline-1 section-title">OUR LOCATION</h2>
      <p className="section-text">
        Find Ambrosia at 274 Park Street, South Melbourne, VIC 3205. We're conveniently located in the heart of South
        Melbourne, offering easy access for locals and visitors alike.
      </p>
      <div
        style={{
          overflow: "hidden",
          maxWidth: "100%",
          width: "80%",
          height: "500px",
          margin: "auto",
        }}
      >
        <div id="embed-map-canvas" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
          <iframe
            title="Ambrosia Restaurant Location in South Melbourne"
            frameBorder="0"
            src="https://www.google.com/maps/embed/v1/place?q=Ambrosia+-+The+Culinary+Exchange,+Park+Street,+South+Melbourne+VIC,+Australia&amp;key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            style={{
              height: "100%",
              width: "100%",
              border: "0",
              filter: "invert(90%)",
            }}
          ></iframe>
        </div>
      </div>
      <img
        alt="shape"
        className="shape shape-1"
        height="178"
        loading="lazy"
        src="/assets/images/shape-7.png"
        width="208"
      />
      <img
        alt="shape"
        className="shape shape-2"
        height="115"
        loading="lazy"
        src="/assets/images/shape-8.png"
        width="120"
      />
    </div>
  </section>
);

export default LocationSection;
