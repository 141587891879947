import React from "react";
import { IoLocationOutline, IoCallOutline, IoMailOutline } from "react-icons/io5";
import { IoIosTimer } from "react-icons/io";
import "./TopBar.css"; // Importing the corresponding CSS

const TopBar = () => (
  <div className="topbar">
    <div className="container">
      <address className="topbar-item">
        <div className="icon">
          <IoLocationOutline />
        </div>
        <span className="span">274 Park Street, South Melbourne 3205</span>
      </address>
      <div className="separator"></div>
      <div className="topbar-item item-2">
        <div className="icon">
          <IoIosTimer />
        </div>
        <span className="span">Dinner: 6pm to 11pm</span>
        <div className="separator"></div>
        <span className="span">Lunch (Sat-Sun): 11am - 3pm</span>
      </div>
      <a className="topbar-item link" href="tel:+61480246220">
        <div className="icon">
          <IoCallOutline />
        </div>
        <span className="span">+61480246220</span>
      </a>
      <div className="separator"></div>
      <a className="topbar-item link" href="mailto:msvfoodsau@gmail.com">
        <div className="icon">
          <IoMailOutline />
        </div>
        <span className="span">msvfoodsau@gmail.com</span>
      </a>
    </div>
  </div>
);

export default TopBar;
