import React, { useState } from "react";
import Carousel from "@itseasy21/react-elastic-carousel";
import { dishGallery } from "../../utils/galleryData";
import "./GallerySection.css";

export const breakPoints = [
  { width: 1, itemsToShow: 1, showArrows: true, pagination: false, enableSwipe: true, enableAutoPlay: true },
  { width: 280, itemsToShow: 3, showArrows: true, pagination: false },
  { width: 550, itemsToShow: 3, showArrows: true, pagination: false },
  { width: 850, itemsToShow: 3, showArrows: true, pagination: false },
  { width: 1600, itemsToShow: 4, showArrows: true, pagination: false },
];

const GallerySection = () => {
  const [selectedDish, setSelectedDish] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);

  const settings = {
    enableAutoPlay: true,
    autoPlaySpeed: 4000,
    breakPoints: breakPoints,
  };

  const handleImageClick = (index) => {
    setSelectedDish(dishGallery[index]);
    setIsZoomed(true);
  };

  const closeZoom = () => {
    setIsZoomed(false);
  };

  return (
    <section
      aria-label="gallery"
      className="section gallery-section text-center"
      id="gallery"
      style={{ backgroundImage: "url('./assets/images/gallery-bg.jpg')", backgroundSize: "cover" }}
    >
      <div className="container">
        <p className="section-subtitle label-2">The Mouth Watering Dishes</p>
        <h2 className="headline-1 section-title">GALLERY</h2>
        <Carousel {...settings}>
          {dishGallery.map((dish, index) => (
            <div
              className="dish-card"
              key={index}
              onClick={() => handleImageClick(index)}
              onMouseOver={(e) => e.currentTarget.classList.add("hover")}
              onMouseOut={(e) => e.currentTarget.classList.remove("hover")}
            >
              <img
                src={dish.dishImageURL}
                alt={`${dish.dishName} - ${dish.dishDescription}`}
                className="dish-image"
                loading="lazy"
              />
              <h3 className="dish-name">{dish.dishName}</h3>
              <p className="dish-desc">{dish.dishDescription}</p>
            </div>
          ))}
        </Carousel>
        {isZoomed && (
          <div className="zoomed-dish-overlay" onClick={closeZoom}>
            <img
              src={selectedDish?.dishImageURL}
              alt={`${selectedDish.dishName} - ${selectedDish.dishDescription}`}
              className="dish-image-zoomed"
            />
            <h3 className="dish-name">{selectedDish?.dishName}</h3>
            <p className="dish-desc">{selectedDish?.dishDescription}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default GallerySection;
