import React, { useState, useEffect } from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import "./HeroSection.css"; // Importing the corresponding CSS
import { Link } from "react-router-dom";

const HeroSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [0, 1, 2]; // Indexes of the slides

  const nextSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 10000); // Change slide every 10 seconds
    return () => clearInterval(slideInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section aria-label="home" className="hero text-center" id="home">
      <ul className="hero-slider" hero-slider="">
        {/* <li className={`slider-item ${activeSlide === 0 ? "active" : ""}`} hero-slider-item="">
          <div className="slider-bg">
            <img alt="" className="img-cover" height="950" src="/assets/images/valentines-day.png" width="1880" />
          </div>
          <p className="label-2 section-subtitle slider-reveal">Romantic Ambiance &amp; Exquisite Cuisine</p>
          <h1 className="display-1 hero-title slider-reveal">
            Celebrate Love <br />
            with Flavors of India
          </h1>
          <p className="body-2 hero-text slider-reveal">
            Indulge in a romantic dinner with your beloved this Valentine's Day
          </p>
          <Link
            className="btn btn-primary slider-reveal book-now"
            to="https://www.opentable.com/booking/experiences-availability?rid=211277&restref=211277&experienceId=256905&utm_source=external&utm_medium=referral&utm_campaign=shared&ot_source=Restaurant%20website"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text text-1">Reserve Your Table</span>
            <span aria-hidden="true" className="text text-2">
              Reserve Now
            </span>
          </Link>
          <Link className="btn btn-primary slider-reveal" to="/valentines-day-dinner">
            <span className="text text-1">Explore the Menu</span>
            <span aria-hidden="true" className="text text-2">
              Discover More
            </span>
          </Link>
        </li> */}
        <li className={`slider-item ${activeSlide === 0 ? "active" : ""}`} hero-slider-item="">
          <div className="slider-bg">
            <img alt="" className="img-cover" height="950" src="/assets/images/hero-slider-1.jpg" width="1880" />
          </div>
          <p className="label-2 section-subtitle slider-reveal">Authentic Flavors &amp; Impeccable Hygiene</p>
          <h1 className="display-1 hero-title slider-reveal">
            Savor the Essence <br />
            of Indian Cuisine
          </h1>
          <p className="body-2 hero-text slider-reveal">
            Gather with loved ones for an unforgettable dining experience
          </p>
          <Link className="btn btn-primary slider-reveal book-now" to="/reservation" rel="noreferrer">
            <span className="text text-1">Reserve Now</span>
            <span aria-hidden="true" className="text text-2">
              Book Your Table
            </span>
          </Link>
          <Link className="btn btn-primary slider-reveal" to="/menu">
            <span className="text text-1">Discover Our Menu</span>
            <span aria-hidden="true" className="text text-2">
              Explore Flavors
            </span>
          </Link>
        </li>
        <li className={`slider-item ${activeSlide === 1 ? "active" : ""}`} hero-slider-item="">
          <div className="slider-bg">
            <img alt="" className="img-cover" height="950" src="/assets/images/hero-slider-2.jpg" width="1880" />
          </div>
          <p className="label-2 section-subtitle slider-reveal">Seasonally Inspired Delights</p>
          <h1 className="display-1 hero-title slider-reveal">
            A Culinary Journey <br />
            Through the Seasons
          </h1>
          <p className="body-2 hero-text slider-reveal">Experience the freshest ingredients in every dish</p>
          <a className="btn btn-primary slider-reveal book-now" href="/reservation" rel="noreferrer">
            <span className="text text-1">Book Your Experience</span>
            <span aria-hidden="true" className="text text-2">
              Reserve a Table
            </span>
          </a>
          <a className="btn btn-primary slider-reveal" href="/menu">
            <span className="text text-1">See Our Specials</span>
            <span aria-hidden="true" className="text text-2">
              View Seasonal Menu
            </span>
          </a>
        </li>
        <li className={`slider-item ${activeSlide === 2 ? "active" : ""}`} hero-slider-item="">
          <div className="slider-bg">
            <img alt="" className="img-cover" height="950" src="/assets/images/hero-slider-3.jpg" width="1880" />
          </div>
          <p className="label-2 section-subtitle slider-reveal">Exquisite &amp; Memorable</p>
          <h1 className="display-1 hero-title slider-reveal">
            Each Dish Tells <br />a Unique Story
          </h1>
          <p className="body-2 hero-text slider-reveal">Join us for a journey of taste and tradition</p>
          <a className="btn btn-primary slider-reveal book-now" href="/reservation" rel="noreferrer">
            <span className="text text-1">Reserve Your Journey</span>
            <span aria-hidden="true" className="text text-2">
              Book a Table
            </span>
          </a>
          <a className="btn btn-primary slider-reveal" href="/menu">
            <span className="text text-1">Explore Our Stories</span>
            <span aria-hidden="true" className="text text-2">
              View Our Dishes
            </span>
          </a>
        </li>
      </ul>
      <button aria-label="slide to previous" className="slider-btn prev" prev-btn="" onClick={prevSlide}>
        <IoChevronBack />
      </button>
      <button aria-label="slide to next" className="slider-btn next" next-btn="" onClick={nextSlide}>
        <IoChevronForward />
      </button>
      <a className="hero-btn has-after" href="/reservation">
        <img alt="booking icon" height="48" src="/assets/images/hero-icon.png" width="48" />
        <span className="label-2 text-center span">Book A Table</span>
      </a>
    </section>
  );
};

export default HeroSection;
