import React from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import SpecialDishSection from "./components/SpecialDishSection";
import TestimonialsSection from "./components/TestimonialsSection";
import ReservationSection from "./components/ReservationSection";
import LocationSection from "./components/LocationSection";
import Footer from "./components/Footer";
import TopBar from "./components/TopBar";
import FeatureSection from "./components/FeatureSection";
import Preloader from "./components/Preloader";
import GallerySection from "./components/GallerySection";
import { AppWrapper } from "./components/AppWrapper";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css"; // Importing the global CSS
import { LandingPage } from "./pages";
import MenuPage from "./pages/MenuPage";
import ValentinesDayPage from "./pages/ValentinesDayPage";
import { Helmet } from "react-helmet";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Preloader />
        <TopBar />
        <Header />
        <AppWrapper>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/menu" element={<MenuPage />} />
            <Route
              path="/reservation"
              element={
                <>
                  <Helmet>
                    <title>Book a Table at Ambrosia - South Melbourne Dining Experience</title>
                    <meta
                      name="description"
                      content="Reserve your table at Ambrosia, South Melbourne's premier dining destination. Experience fine dining with a personal touch in an elegant setting. Book now to secure your spot."
                    />
                  </Helmet>
                  <ReservationSection />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <Helmet>
                    <title>Our Story - Ambrosia, a Culinary Journey in South Melbourne</title>
                    <meta
                      name="description"
                      content="Discover the story behind Ambrosia, a culinary landmark in South Melbourne. Learn about our journey, passion for cuisine, and commitment to exceptional dining experiences."
                    />
                  </Helmet>
                  <AboutSection />
                  <FeatureSection />
                </>
              }
            />
            <Route
              path="/gallery"
              element={
                <>
                  <Helmet>
                    <title>Ambrosia's Gallery - Visual Feast of Culinary Artistry</title>
                    <meta
                      name="description"
                      content="Browse Ambrosia's gallery to witness a visual celebration of our culinary delights. Each image captures the essence of our dishes' exquisite tastes and presentation."
                    />
                  </Helmet>
                  <GallerySection />
                </>
              }
            />
            <Route path="/special" element={<SpecialDishSection />} />
            <Route path="/testimonials" element={<TestimonialsSection />} />
            <Route
              path="/location"
              element={
                <>
                  <Helmet>
                    <title>Find Us - Visit Ambrosia in South Melbourne</title>
                    <meta
                      name="description"
                      content="Locate Ambrosia in the heart of South Melbourne. Whether you're a local or a visitor, find directions and details to join us for an unforgettable dining experience."
                    />
                  </Helmet>
                  <LocationSection />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <Helmet>
                    <title>Contact Ambrosia - Get in Touch and Find Us in South Melbourne</title>
                    <meta
                      name="description"
                      content="Reach out to Ambrosia in South Melbourne for reservations, inquiries, or feedback. Access our contact details, location map, and opening hours. We're here to provide you with an exceptional dining experience."
                    />
                  </Helmet>
                  <ReservationSection />
                </>
              }
            />
            <Route path="/valentines-day-dinner" element={<ValentinesDayPage />} />
            <Route path="*" element={<HeroSection />} />
          </Routes>
        </AppWrapper>
        <Footer />
      </div>
    </Router>
  );
};

/*
const App = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      // Using setTimeout to add a delay to ensure the page is fully rendered
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth" });
          }, 2100);
        }
      }, 0);
    }
  }, []); // Empty dependency array means this effect will run once after the initial render

  return (
    <div className="App">
      <Preloader />
      <TopBar />
      <Header />
      <main>
        <HeroSection />
        <ServiceSection />
        <AboutSection />
        <MenuSection />
        <GallerySection />
        <SpecialDishSection />
        <TestimonialsSection />
        <ReservationSection />
        <FeatureSection />
        <LocationSection />
      </main>
      <Footer />
    </div>
  );
};
*/

export default App;
