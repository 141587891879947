import React from "react";
import "./MenuSection.css";

const MenuSection = ({ title, subtitle, items }) => (
  <section aria-label={`${title}-label`} className="section menu" id={title.toLowerCase()}>
    <div className="container">
      <p className="section-subtitle text-center label-2">{subtitle}</p>
      <h2 className="headline-1 section-title text-center">{title}</h2>
      <ul className="grid-list">
        {items?.map((item, index) => (
          <li key={index} className="menu-item-li">
            <button className="menu-card-link">
              <div className="menu-card hover:card">
                <figure className="card-banner img-holder-menu">
                  <img alt={`Menu item: ${item.name}`} src={item.image} className="img-fit" loading="lazy" />
                </figure>
                <div>
                  <div className="title-wrapper">
                    <h3 className="menu-item-title">{item.name}</h3>
                  </div>
                  <p className="card-text label-1">{item.description}</p>
                </div>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default MenuSection;
