import React from "react";
import { valentinesDayMenu } from "../utils/valentinesDayMenu";
import SpecialEvent from "../components/SpecialEvent";
import { Helmet } from "react-helmet";
import MenuSection from "../components/MenuSection";

const ValentinesDayPage = () => {
  const menu = (
    <>
      <MenuSection title="First Course + Welcome Drink" items={valentinesDayMenu.firstCourse} />
      <MenuSection title="Entrées (choose any two)" items={valentinesDayMenu.entrees} />
      <MenuSection title="Main Courses (choose one)" items={valentinesDayMenu.mains} />
      <MenuSection title="Desserts (choose one)" items={valentinesDayMenu.desserts} />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Valentine's Day Dinner at Ambrosia, South Melbourne</title>
        <meta
          name="description"
          content="Join us at Ambrosia for an exclusive Valentine's Day dinner experience in South Melbourne. Enjoy our special 4-course romantic dinner. Limited seats available."
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "http://schema.org",
        "@type": "Event",
        "name": "Valentine's Day Dinner",
        "startDate": "2024-02-14T19:00",
        "endDate": "2024-02-17T23:00",
        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
        "eventStatus": "https://schema.org/EventScheduled",
        "location": {
          "@type": "Place",
          "name": "Ambrosia - The Culinary Exchange",
          "address": "274 Park Street, South Melbourne 3205, VIC"
        },
        "image": ["https://theambrosiarestaurant.com.au/"],
        "description": "A special 4-course Valentine's Day dinner at Ambrosia. Join us for a romantic evening with exquisite cuisine in the heart of South Melbourne.",
        "offers": {
          "@type": "Offer",
          "url": "https://theambrosiarestaurant.com.au/valentines-day-dinner",
          "price": "80",
          "priceCurrency": "AUD"
        },
        "performer": "Bollywood Instrumental Music",
        "organizer": {
          "@type": "Organization",
          "name": "Ambrosia - The Culinary Exchange",
          "url": "https://theambrosiarestaurant.com.au"
        }
      }
    `}
        </script>
      </Helmet>
      <SpecialEvent
        title="Valentine's Day Dinner at Ambrosia in South Melbourne"
        description="Join us for an unforgettable romantic evening with our exclusive Valentine's Day 4-course menu. Each dish is carefully crafted to make your celebration of love truly special. Plus, a rose for your other half to make the evening memorable."
        menu={menu}
        pricing="$80 per person + drinks (10% Off)"
        bookingLink="/reservation"
      />
    </>
  );
};

export default ValentinesDayPage;
