import React from "react";
import "./SectionHeading.css"; // Make sure to create this CSS file

const SectionHeading = ({ title }) => {
  return (
    <div>
      <h3 className="section-heading headline-1 ">{title}</h3>
    </div>
  );
};

export default SectionHeading;
