import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import "./Header.css"; // Importing the corresponding CSS

const Header = () => {
  const [isNavbarActive, setNavbarActive] = useState(false);
  const [isHeaderVisible, setHeaderVisible] = useState(true);
  const [isHeaderActive, setHeaderActive] = useState(false);

  const toggleNavbar = () => {
    setNavbarActive(!isNavbarActive);
  };

  const isMobile = () => {
    return window.innerWidth <= 768;
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const onScroll = () => {
      const st = window.scrollY || document.documentElement.scrollTop;
      if (st > lastScrollTop && st > 50) {
        // Scrolling down and not at the top of the page
        setHeaderVisible(false);
      } else if (st < lastScrollTop && st > 50) {
        // Scrolling up and not at the top of the page
        setHeaderVisible(true);
        setHeaderActive(true);
      } else {
        // At the top of the page
        setHeaderVisible(true);
        setHeaderActive(false);
      }
      lastScrollTop = st;
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  if (!isHeaderVisible) {
    return null;
  }

  return (
    <header
      style={{ backgroundImage: "url('./assets/images/footer-bg.jpg')" }}
      className={`header ${isHeaderVisible && isHeaderActive ? "active" : ""} header_spacing`}
      data-header
    >
      <div className="container">
        <Link to="/" className="logo" style={{ marginTop: "10px" }}>
          <img alt="Ambrosia - Home" height="auto" src="/assets/images/logo.svg" width={isMobile() ? "300" : "420"} />
        </Link>
        <nav className={`navbar ${isNavbarActive ? "active" : ""}`} data-navbar>
          <button aria-label="close menu" className="close-btn" onClick={toggleNavbar}>
            <AiOutlineClose />
          </button>
          <Link to="/" className="logo">
            <img alt="Ambrosia - Home" height="50" src="/assets/images/logo.svg" width="160" />
          </Link>
          <ul className="navbar-list">
            <li className="navbar-item">
              <NavLink className={({ isActive }) => `navbar-link hover-underline ${isActive ? "active" : ""}`} to="/">
                <div className="separator"></div>
                <span className="span">Home</span>
              </NavLink>
            </li>
            {/* <li className="navbar-item">
              <NavLink
                className={({ isActive }) => `navbar-link hover-underline ${isActive ? "active" : ""}`}
                to="/valentines-day-dinner"
                onClick={() => setNavbarActive(false)}
              >
                <div className="separator"></div>
                <span className="span">Valentine's Day Dinner</span>
              </NavLink>
            </li> */}
            <li className="navbar-item">
              <NavLink
                className={({ isActive }) => `navbar-link hover-underline ${isActive ? "active" : ""}`}
                to="/menu"
                onClick={() => setNavbarActive(false)}
              >
                <div className="separator"></div>
                <span className="span">Menus</span>
              </NavLink>
            </li>
            <li className="navbar-item">
              <NavLink
                className={({ isActive }) => `navbar-link hover-underline ${isActive ? "active" : ""}`}
                to="/about"
                onClick={() => setNavbarActive(false)}
              >
                <div className="separator"></div>
                <span className="span">Our Story</span>
              </NavLink>
            </li>
            <li className="navbar-item">
              <NavLink
                className={({ isActive }) => `navbar-link hover-underline ${isActive ? "active" : ""}`}
                to="/gallery"
                onClick={() => setNavbarActive(false)}
              >
                <div className="separator"></div>
                <span className="span">Gallery</span>
              </NavLink>
            </li>
            <li className="navbar-item">
              <NavLink
                className={({ isActive }) => `navbar-link hover-underline ${isActive ? "active" : ""}`}
                to="/location"
                onClick={() => setNavbarActive(false)}
              >
                <div className="separator"></div>
                <span className="span">Find Us</span>
              </NavLink>
            </li>
            <li className="navbar-item">
              <NavLink
                className={({ isActive }) => `navbar-link hover-underline ${isActive ? "active" : ""}`}
                to="/contact"
                onClick={() => setNavbarActive(false)}
              >
                <div className="separator"></div>
                <span className="span">Contact</span>
              </NavLink>
            </li>
            {isMobile() && (
              <li className="navbar-item">
                <NavLink
                  className={({ isActive }) => `navbar-link hover-underline ${isActive ? "active" : ""}`}
                  to="/reservation"
                  onClick={() => setNavbarActive(false)}
                >
                  <div className="separator"></div>
                  <span className="span">Reserve A Table</span>
                </NavLink>
              </li>
            )}
          </ul>
          <div className="text-center">
            <p className="headline-1 navbar-title">Visit Us</p>
            <address className="body-4">
              274 Park Street, <br />
              South Melbourne, VIC 3205
            </address>
            <p className="body-4 navbar-text">Lunch (Sat-Sun): 11am - 3pm | Dinner: 6pm to 11pm</p>
            <Link className="body-4 sidebar-link" to="mailto:msvfoodsau@gmail.com">
              msvfoodsau@gmail.com
            </Link>
            <div className="separator"></div>
            <p className="contact-label">Booking Request</p>
            <Link className="body-1 contact-number hover-underline" to="tel:+61480246220">
              +61480246220
            </Link>
          </div>
        </nav>
        <Link to="/reservation" className="btn btn-secondary" onClick={() => setNavbarActive(false)}>
          <span className="text text-1">Reserve a Table</span>
          <span aria-hidden="true" className="text text-2">
            Reserve a Table
          </span>
        </Link>
        <button
          aria-label="open menu"
          style={{ marginTop: isMobile() ? "0" : "10px" }}
          className="nav-open-btn"
          onClick={toggleNavbar}
        >
          <span className="line line-1"></span>
          <span className="line line-2"></span>
          <span className="line line-3"></span>
        </button>
        <div className={`overlay ${isNavbarActive ? "active" : ""}`} data-overlay></div>
      </div>
    </header>
  );
};

export default Header;
