import React, { useState, useEffect } from "react";
import "./ReservationSection.css"; // Importing the corresponding CSS

const ReservationSection = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    // Remove existing widget content
    document.getElementById("quandoo-booking-widget").innerHTML = "";

    // Add the Quandoo booking widget
    const script = document.createElement("script");
    script.src = "https://booking-widget.quandoo.com/index.js";
    script.setAttribute("data-merchant-id", "100280");
    script.setAttribute("data-theme", "brand"); // Set to 'brand' to match your color scheme
    script.async = true;

    document.getElementById("quandoo-booking-widget").appendChild(script);

    if (isMobile) {
      document.getElementById("quandoo-booking-widget").style.display = "flex";
      document.getElementById("quandoo-booking-widget").style.justifyContent = "center";
      document.getElementById("quandoo-booking-widget").style.alignItems = "center";
    }
  }, [isMobile]);

  return (
    <section aria-label="reservation" className="reservation section service bg-black-10 text-center" id="contact">
      <div className="container">
        <div className="form bg-black-10">
          <div className="form-left">
            <h2 className="headline-1 text-center">Online Reservation</h2>
            <p className="form-text text-center">
              Booking request{" "}
              <a className="link" href="tel:+61480246220">
                +61480246220
              </a>{" "}
              or fill out the order form
            </p>
            <div id="quandoo-booking-widget"></div> {/* This is where the Quandoo widget will appear */}
          </div>
          <div
            className="form-right text-center"
            style={{ backgroundImage: "url('./assets/images/form-pattern.png')" }}
          >
            <h2 className="headline-1 text-center">Contact Us</h2>
            <p className="contact-label">Booking Request</p>
            <a className="body-1 contact-number hover-underline" href="tel:+61480246220">
              +61480246220
            </a>
            <div className="separator"></div>
            <p className="contact-label">Location</p>
            <address className="body-4">
              274 Park Street <br />
              South Melbourne, VIC 3205
            </address>
            <p className="contact-label">Lunch Time</p>
            <p className="body-4">
              Saturday to Sunday <br />
              1pm - 4pm
            </p>
            <p className="contact-label">Dinner Time</p>
            <p className="body-4">
              Wednesday to Sunday <br />
              4pm - 10.00pm (9pm on Weekends)
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReservationSection;
