const placeholderImage = "/assets/images/food/placeholder.png";

const menuData = {
  entrees: [
    {
      name: "Samosa on Wheels",
      description: "Flaky pastry rolls with spicy potato filling.",
      image: "/assets/images/food/samosa-on-wheels.jpeg",
    },
    {
      name: "Cajun Potato",
      description: "Crispy potatoes seasoned with zesty Cajun spices and sauce.",
      image: placeholderImage,
    },
    {
      name: "Chicken Lollipop",
      description: "Deep-fried chicken drumsticks coated in a flavorful sauce.",
      image: "/assets/images/food/chicken-lollipop.jpeg",
    },
    {
      name: "Garlic Creamy Prawns",
      description: "Succulent prawns in a creamy garlic sauce.",
      image: placeholderImage,
    },
    {
      name: "Pani-Puri Ragada",
      description: "Delightful Indian street snack crispy shells filled with spicy tangy water.",
      image: "/assets/images/food/pani-puri.jpeg",
    },
    {
      name: "Crunchy Bhaji Fiesta",
      description: "A crispy and savory delight of veggies fritters with blend of aromatic spices.",
      image: placeholderImage,
    },
    {
      name: "Masala Papad Platter",
      description: "Crunchy papads topped with pica de gallo.",
      image: "/assets/images/food/masala-papad.jpeg",
    },
    {
      name: "Coral Crispies",
      description: "Delicious and crispy fried fish with a tangy twist.",
      image: placeholderImage,
    },
    {
      name: "Vada Bao",
      description:
        "A unique fusion of the classic Mumbai Vada and soft bao buns. Expect a tantalizing blend of spiced potato fritters served in fluffy bao buns, offering a perfect balance of soft and crunchy textures.",
      image: "/assets/images/food/vada-bao.jpeg",
    },
    {
      name: "Pav Burger",
      description:
        "A spicy and flavorful vegetarian burger. Expect a deliciously seasoned potato patty, topped with a spicy chutney, and served in a soft pav bun.",
      image: "/assets/images/food/pao-burger.jpeg",
    },
  ],
  mains: [
    {
      name: "Butter Chicken Lasagna",
      description: "Creamy butter chicken layered in lasagna sheets.",
      image: "/assets/images/food/butter-chicken-lasagna.jpeg",
    },
    { name: "Indian Sushi", description: "Indian-inspired sushi rolls with unique flavors.", image: placeholderImage },
    {
      name: "Arancini Malai",
      description: "Rich and creamy curry with soft cheese dumplings.",
      image: "/assets/images/food/aranchini-malai.jpeg",
    },
    {
      name: "Go-Green Chicken",
      description: "Fragrant creamy curry with tender chicken in a green sauce.",
      image: "/assets/images/food/green-chicken.jpeg",
    },
    { name: "Mexican Dosa", description: "South Indian crepe with a Mexican inspired twist.", image: placeholderImage },
    {
      name: "Fish Curry",
      description: "Tangy and aromatic curry with tender fish pieces.",
      image: "/assets/images/food/fish-curry.jpeg",
    },
    {
      name: "Prawn Curry",
      description: "Creamy and aromatic curry with tender prawns.",
      image: "/assets/images/food/fish-curry.jpeg",
    },
    {
      name: "Dal Makhini",
      description: "Tempered lentil gravy with aromatic spices.",
      image: "/assets/images/food/dal-makhani.jpeg",
    },
    {
      name: "Chicken Biryani",
      description: "A rich blend of tender chicken with basmati rice.",
      image: "/assets/images/food/chicken-biryani.jpeg",
    },
    {
      name: "Mutton Biryani",
      description: "A rich blend of tender mutton with basmati rice.",
      image: "/assets/images/food/chicken-biryani.jpeg",
    },
  ],
  desserts: [
    {
      name: "Sizzling Fudge Fantasy",
      description:
        "A decadent dessert featuring a rich and gooey chocolate fudge served sizzling hot. It's a chocolate lover's dream, often accompanied by vanilla ice cream melting over the top for a perfect hot-and-cold combination.",
      image: placeholderImage,
    },
    {
      name: "Rasmalai Tiramisu",
      description: "A fusion of Indian Rasmalai and Italian Tiramisu.",
      image: "/assets/images/food/rasmalai-tiramisu.jpeg",
    },
    {
      name: "Gulab Jamun w/ Ice Cream",
      description: "Creamy cheesecake with traditional Gulab Jamun.",
      image: "/assets/images/food/gulab-jamun.png",
    },
    {
      name: "Ambrosial Mango Royale",
      description: "A mango dessert that will leave you wanting more.",
      image: "/assets/images/food/ambrosial-mango-royale.jpeg",
    },
    {
      name: "Bread Butter Pudding",
      description:
        "A comforting, classic dessert made from layers of buttered bread, raisins, and custard. Baked until golden and served warm, it's a heartwarming dish, perfect for those who enjoy a sweet and simple treat.",
      image: placeholderImage,
    },
  ],
  sides: [
    {
      name: "Roti",
      description:
        "Freshly made, soft, and fluffy Indian bread, cooked to perfection on a flat griddle. It's a staple accompaniment to Indian meals, perfect for scooping up curries or gravies.",
      image: placeholderImage,
    },
    {
      name: "Naan",
      description:
        "A traditional Indian leavened bread, baked in a clay oven. It's soft, pillowy, and slightly charred, making it ideal for pairing with rich, creamy dishes.",
      image: placeholderImage,
    },
    {
      name: "Jeera Rice",
      description:
        "Basmati rice flavored with cumin (Jeera) and other aromatic spices. It's a fragrant and flavorful side dish, complementing a variety of Indian curries and gravies.",
      image: placeholderImage,
    },
    {
      name: "Chips",
      description:
        "Classic, crispy potato chips, seasoned with a hint of salt. They are the perfect crunchy side or snack, ideal for pairing with any meal or enjoying on their own.",
      image: placeholderImage,
    },
  ],
  cocktails: [
    {
      name: "Chocolate Amarula",
      description: "Strong creamy cocktail with delicious South African Amarula.",
      image: placeholderImage,
    },
    { name: "Lychee Vodka", description: "Vodka with lychee and Cinzano Bianco mixture.", image: placeholderImage },
    {
      name: "Gin and Cabernet Sour",
      description: "For all gin and wine lovers blend of orange Cointreau and red wine Cabernet.",
      image: placeholderImage,
    },
    {
      name: "Watermelon Margarita",
      description: "Mixture of watermelon, tequila, and lime juice with a pinch of salt.",
      image: placeholderImage,
    },
    { name: "Berry Blast", description: "A refreshing mix of berry puree and vodka.", image: placeholderImage },
    { name: "Mojito", description: "Classic mojito with fresh mint and lime.", image: placeholderImage },
    {
      name: "Pina Colada",
      description: "A sweet cocktail made with rum, coconut cream, and pineapple juice.",
      image: placeholderImage,
    },
    {
      name: "Cosmopolitan",
      description: "Vodka, triple sec, cranberry juice, and freshly squeezed lime juice.",
      image: placeholderImage,
    },
  ],
  wines: {
    red: [
      { name: "2022 St Hallett Faith Shiraz", description: "Barossa Valley, SA, Australia", image: placeholderImage },
      { name: "2020 Red Hill Estate Cabernet", description: "Coonawarra, SA, Australia", image: placeholderImage },
      { name: "2021 La Petite Perriere Pinot Noir", description: "Loire Valley, France", image: placeholderImage },
      { name: "2020 Grant Burge Hillcot Merlot", description: "Barossa Valley", image: placeholderImage },
    ],
    white: [
      { name: "2022 Mud House Sauvignon Blanc", description: "Marlborough, New Zealand", image: placeholderImage },
      { name: "2019 Penfolds Maxs Chardonnay", description: "Adelaide Hills, SA, Australia", image: placeholderImage },
      { name: "Ara Single Estate Pinot Gris", description: "Marlborough, New Zealand", image: placeholderImage },
      { name: "2021 Penfolds Bin 51 Riesling", description: "Eden Valley, SA", image: placeholderImage },
      { name: "Collefrisio Filare Trebbiano d'Abruzzo", description: "Central Italy", image: placeholderImage },
      { name: "Mantellassi Lucumone Vermentino DOC", description: "Tuscan Maremma, Italy", image: placeholderImage },
    ],
    rose: [
      { name: "La Vieille Ferme Rose", description: "Vin de France, France", image: placeholderImage },
      { name: "Brown Brothers Moscato Rose", description: "King Valley, Australia", image: placeholderImage },
    ],
    sparkling: [
      { name: "Brown Brothers Prosecco", description: "King Valley, Australia", image: placeholderImage },
      { name: "Palmer & Co Brut Reserve", description: "France Champagne", image: placeholderImage },
    ],
  },
  spirits: [
    { name: "Glenfiddich 12 Years", description: "", image: placeholderImage },
    { name: "Woodford Reserve", description: "", image: placeholderImage },
    { name: "Jameson Irish Whiskey", description: "", image: placeholderImage },
    { name: "Jack Daniels", description: "", image: placeholderImage },
  ],
  liqueur: [
    { name: "Janneau Grand Armagnac", description: "", image: placeholderImage },
    { name: "Chambord", description: "", image: placeholderImage },
    { name: "Frangelico Hazelnut", description: "", image: placeholderImage },
    { name: "Baileys", description: "", image: placeholderImage },
  ],
  beers: [
    { name: "Corona Extra 355ml", description: "", image: placeholderImage },
    { name: "Sapporo Bullet 650ml", description: "", image: placeholderImage },
    { name: "150 Lashes Pale Ale 330ml", description: "", image: placeholderImage },
    { name: "Great Northern Super Crisp 330 ml", description: "", image: placeholderImage },
  ],
  mocktails: [
    {
      name: "Mango Lassi Delight",
      description: "Velvety yogurt blended with succulent mangoes creating a refreshing Mango Lassi Delight.",
      image: placeholderImage,
    },
    {
      name: "Pulse Mojito",
      description: "Featuring pulse candies sugar mixed with black salt and garnished with mint.",
      image: placeholderImage,
    },
  ],

  // Additional categories can be added similarly
};

export default menuData;
