import React from "react";
import "./Preloader.css"; // Importing the corresponding CSS

const Preloader = () => {
  const [isPreloaderVisible, setPreloaderVisible] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setPreloaderVisible(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  if (!isPreloaderVisible) {
    return null;
  }

  return (
    <div className="preload">
      <div className="circle"></div>
      <p className="text">Ambrosia</p>
    </div>
  );
};

export default Preloader;
