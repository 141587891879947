export const dishGallery = [
  {
    dishName: "Bread Butter Pudding",
    dishDescription: "Served With Ice-Cream & Toffee Sauce",
    dishImageURL: "./assets/images/food/bbp.jpeg",
  },
  {
    dishName: "Butter Chicken Lasagna",
    dishDescription: "Creamy butter chicken layered in lasagna sheets",
    dishImageURL: "./assets/images/food/butter-chicken-lasagna.jpeg",
  },
  {
    dishName: "Chicken Biryani",
    dishDescription: "A rich blend of tender chicken with basmati rice",
    dishImageURL: "./assets/images/food/chicken-biryani.jpeg",
  },
  {
    dishName: "Chicken Lollipop",
    dishDescription: "Deep-fried chicken drumsticks coated in a flavourful sauce",
    dishImageURL: "./assets/images/food/chicken-lollipop.jpeg",
  },
  {
    dishName: "Coral Crispies",
    dishDescription: "Delicious and crispy fried FISH with a tangy twist.",
    dishImageURL: "./assets/images/food/coral-crispy.jpeg",
  },
  {
    dishName: "Dal Makhani",
    dishDescription: "A rich blend of lentils with cream and butter",
    dishImageURL: "./assets/images/food/dal-makhani.jpeg",
  },
  {
    dishName: "Gin Sour",
    dishDescription: "Featuring gin, fresh lemon juice with egg white and a pinch of bitter.",
    dishImageURL: "./assets/images/food/drink1.jpeg",
  },
  {
    dishName: "Fish Curry",
    dishDescription: "Tangy and aromatic curry with tender fish pieces.",
    dishImageURL: "./assets/images/food/fish-curry.jpeg",
  },
  {
    dishName: "Green Chicken",
    dishDescription: "Fragrant creamy curry with tender chicken in a green sauce.",
    dishImageURL: "./assets/images/food/green-chicken.jpeg",
  },
  {
    dishName: "Chocolate Amarula",
    dishDescription: "Strong creamy cocktail with delicious South African Amarula",
    dishImageURL: "./assets/images/food/drink2.jpeg",
  },
  {
    dishName: "Pao Burger",
    dishDescription: "A fusion of Pao Bhaji's patty wrapped in a Burger Bun.",
    dishImageURL: "./assets/images/food/pao-burger.jpeg",
  },
  {
    dishName: "Rasmalai Meets Tiramisu",
    dishDescription: "A fusion of Indian Rasmalai and Italian Tiramisu.",
    dishImageURL: "./assets/images/food/rasmalai-tiramisu.jpeg",
  },
  {
    dishName: "Samosa on Wheels",
    dishDescription: "Flaky pastry rolls with spicy potato filling.",
    dishImageURL: "./assets/images/food/samosa-on-wheels.jpeg",
  },
  {
    dishName: "Sago Lollipop",
    dishDescription: "Deep-fried Sago drumsticks coated in a flavourful sauce.",
    dishImageURL: "./assets/images/food/sago-lollipop.jpeg",
  },
];
