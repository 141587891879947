import React from "react";
import "./FeatureSection.css"; // Importing the corresponding CSS

const FeatureSection = () => (
  <section aria-label="features" className="section features text-center">
    <div className="container">
      <p className="section-subtitle label-2">Why Choose Us</p>
      <h2 className="headline-1 section-title">Our Strength</h2>
      <ul className="grid-list">
        <li className="feature-item">
          <div className="feature-card">
            <div className="card-icon">
              <img alt="icon" height="80" loading="lazy" src="/assets/images/features-icon-1.png" width="100" />
            </div>
            <h3 className="title-2 card-title">Fresh Environment</h3>
            <p className="label-1 card-text">
              We believe that ambience plays a crucial role in a dining experience. Our restaurant offers a fresh and
              vibrant environment in South Melbourne, with an open layout and comfortable seating. The modern yet cozy
              atmosphere adds to the charm of enjoying our fusion delicacies.
            </p>
          </div>
        </li>
        <li className="feature-item">
          <div className="feature-card">
            <div className="card-icon">
              <img alt="icon" height="80" loading="lazy" src="/assets/images/features-icon-2.png" width="100" />
            </div>
            <h3 className="title-2 card-title">Innovative Menu</h3>
            <p className="label-1 card-text">
              Our menu is a testament to our love for culinary innovation. We've carefully curated a selection of dishes
              that showcase the diversity and versatility of Indian flavours, seamlessly blending them with elements
              from various world cuisines.
            </p>
          </div>
        </li>
        <li className="feature-item">
          <div className="feature-card">
            <div className="card-icon">
              <img alt="icon" height="80" loading="lazy" src="/assets/images/features-icon-3.png" width="100" />
            </div>
            <h3 className="title-2 card-title">Sustainable Practices</h3>
            <p className="label-1 card-text">
              We believe in the importance of sustainability in our operations. From sourcing ingredients locally from
              Melbourne wherever possible, to minimizing food waste, we're committed to providing a dining experience
              that is mindful of our environment.
            </p>
          </div>
        </li>
        <li className="feature-item">
          <div className="feature-card">
            <div className="card-icon">
              <img alt="icon" height="80" loading="lazy" src="/assets/images/features-icon-4.png" width="100" />
            </div>
            <h3 className="title-2 card-title">Exceptional Service</h3>
            <p className="label-1 card-text">
              Customer satisfaction is at the heart of our service. Our friendly and attentive staff are dedicated to
              providing you with an exceptional dining experience at South Melbourne, ensuring that your every need is
              catered to.
            </p>
          </div>
        </li>
      </ul>
      <img
        alt="shape"
        className="shape shape-1"
        height="178"
        loading="lazy"
        src="/assets/images/shape-7.png"
        width="208"
      />
      <img
        alt="shape"
        className="shape shape-2"
        height="115"
        loading="lazy"
        src="/assets/images/shape-8.png"
        width="120"
      />
    </div>
  </section>
);

export default FeatureSection;
